import { Timestamp } from '@firebase/firestore-types';

export interface Request {
  uid: string;
  date: Timestamp;
  internalReference: string;
  candidateUid: string;
  practiceUid: string;
  positionUid: string;
  status: RequestStatus;
}

export enum RequestStatus {
  open = 'Open',
  appointed = 'Appointed',
  closed = 'Closed'
}












































































































































































import Vue from 'vue';
import { User } from '@/models/user.model';
import { VetSkillType } from '@/models/position.model';
import { Practice } from '@/models/practice.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { db, storage } from '@/main';
import { Place } from '@/models/place.model';
import { Position } from '@/models/position.model';
import { Request, RequestStatus } from '@/models/request.model';

export default Vue.extend({
  name: 'PositionView',
  components: {
    SalaryValue: () => import('@/components/SalaryValue.vue')
  },
  data() {
    return {
      practice: {} as Practice,
      position: {} as Position,
      request: {} as Request,
      candidate: {} as User,
      imageUrl: String,
      cvUrl: {} as null | string,
      place: {} as Place,
      placeLoading: false,
      distance: String,
      duration: String,
      distanceRetrieved: false,
      showContactDetails: false
    };
  },
  methods: {
    checkIfFunction(value: any): boolean {
      return typeof value === 'function';
    },
    getRequest() {
      db.collection('requests')
        .doc(this.$route.query.requestUid as string)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.request = doc.data() as Request;
        });
    },
    getPosition() {
      db.collection('positions')
        .doc(this.$route.query.positionUid as string)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.position = doc.data() as Position;
        });
    },
    getCandidate() {
      db.collection('users')
        .doc(this.$route.query.candidateUid as string)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.candidate = doc.data() as User;
          if (this.candidate.profileImageRef) {
            this.imageUrl = await Promise.resolve(
              storage.ref(this.candidate.profileImageRef).getDownloadURL()
            );
          }
          if (this.candidate.cvFileRef) {
            this.cvUrl = await Promise.resolve(
              storage.ref(this.candidate.cvFileRef).getDownloadURL()
            );
          } else {
            this.cvUrl = null;
          }
        });
    },
    getPractice() {
      db.collection('practices')
        .doc(this.$route.query.practiceUid as string)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.practice = doc.data() as Practice;
          this.getDistance(this.practice.googlePlaceId);
        });
    },
    getDistance(practicePlaceId: string) {
      return firebase
        .functions()
        .httpsCallable('getDistanceBetweenPlaces')({
          origin: this.candidate.googlePlaceId,
          destination: practicePlaceId
        })
        .then(result => {
          this.distance = result.data.distance.text;
          this.duration = result.data.duration.text;
          this.distanceRetrieved = true;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getSkillsMatchPercentage(skillsRequired: VetSkillType[]): string {
      const intersection = skillsRequired.filter(element =>
        this.candidate.vetSkillTypes.includes(element)
      );
      return ((intersection.length / skillsRequired.length) * 100).toFixed(0);
    },
    requestInterview() {
      const request = {
        uid: db.collection('requests').doc().id,
        date: firebase.firestore.Timestamp.now(),
        internalReference: this.position.internalReference,
        candidateUid: this.candidate.uid,
        practiceUid: this.practice.uid,
        positionUid: this.position.uid,
        status: RequestStatus.open
      } as Request;
      db.collection('requests')
        .add(request)
        .then(() => {
          this.$router.push({
            name: 'RequestView',
            query: {
              requestUid: request.uid,
              candidateUid: request.candidateUid,
              positionUid: request.positionUid,
              practiceUid: request.practiceUid
            }
          });
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  },
  created() {
    this.getRequest();
    this.getPosition();
    this.getCandidate();
    this.getPractice();
  }
});
